@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background: url(/assets/Chase_Fade_Cole_Pic_1_copy.png) no-repeat fixed;
  background-size: cover;
  background-color: black;
  height: auto;
  max-width: 100%;
  scroll-behavior: smooth;
}

/* @keyframes fadeInAnimation { 
    0% { 
        opacity: 0; 
    } 
    100% { 
        opacity: 1; 
    } 
  }
    */

/* 
  .homeTextDiv {
      display: inline-block;
      top: 225px;
      width: 100%;
      
  } */

.leftside {
  background: transparent;
}

.homeText {
  text-align: center;
  font-family: 'Barlow', sans-serif;
  font-size: 24px;
  font-weight: bolder;
  color: white;
  font-family: 'Roboto', sans-serif;
  line-height: 30px;
  text-align: left;
  padding-top: 25px;
}

.homeIntro {
  margin: auto;
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 28px;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 1px;
  font-weight: 700;
  color: white;
}

/* Galaxy Fold */
@media (min-width: 280px) {
  body {
    background-position: top 35px;
  }

  .homeCard-body {
    margin-left: 38%;
    /* margin-right: 1%; */
    margin-top: 50px;
  }

  .homeTextDiv {
    text-align: left;
    width: 100%;
  }

  .homeTitle {
    font-size: 15px;
    text-align: center;
    width: 100%;

  }

  .homeText {
    font-size: 15px;
    line-height: 20px;
  }
}

/* Min height 320 */
@media (min-width: 320px) {
  body {
    background-position: top 35px;
  }

  .homeCard-body {
    margin-left: 35%;
    /* margin-right: 1%; */
    margin-top: 50px;
  }

  .homeTextDiv {
    text-align: left;
    width: 100%;
  }

  .homeTitle {
    font-size: 15px;
    text-align: center;
    width: 100%;

  }

  .homeText {
    font-size: 15px;
    line-height: 20px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 480px) {
  /* background: url(../../backgroundImages/Revenge_sessions_5_480pxwidth.jpg) no-repeat fixed;} */

  .homeCard-body {
    margin-left: 35%;
    margin-top: 50px;
  }

  .homeTextDiv {
    text-align: left;
    width: 100%;
  }

  .homeTitle {
    font-size: 25px;
    text-align: center;

  }

  .homeText {
    font-size: 15px;
    line-height: 20px;
  }
}

@media only screen and (min-width: 600px) {
  body {
    background-position: top 65px left 10px;
  }

  .homeCard-body {
    margin-left: 30%;
    margin-top: 50px;
  }

  .homeTextDiv {
    text-align: left;
    width: 90%;
  }

  .homeTitle {
    font-size: 28px;
    text-align: center;

  }

  .homeText {
    font-size: 15px;
    line-height: 20px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) {
  .homeCard-body {
    margin-left: 25%;
    margin-top: 50px;
  }

  .homeTextDiv {
    top: 205px;
    width: 100%;
  }

  .homeTitle {
    font-size: 25px;
    text-align: center;

  }

  .homeText {
    font-size: 15px;
  }

}

@media (min-width: 995px) {
  .homeCard-body {
    margin-left: 10%;
    margin-top: 50px;
  }

  .homeTextDiv {
    top: 205px;
    width: 100%;
  }

  .homeTitle {
    font-size: 25px;
    text-align: center;

  }

  .homeText {
    font-size: 15px;
  }

}

/*  Large devices (laptops/desktops, 992px and up) */
@media (min-width: 1024px) {
  .homeCard-body {
    margin-left: 10%;
    margin-top: 50px;
  }

  .homeTextDiv {
    width: 100%;
  }

  .homeTitle {
    font-size: 30px;
    text-align: center;

  }

  .homeText {
    font-size: 15px;
    /* line-height: 30px; */
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media(min-width: 1200px) {
  .homeCard-body {
    margin-left: 15%;
    margin-top: 10px;
  }

  .homeTextDiv {
    padding-top: 55px;
    width: 90%;
  }

  .homeTitle {
    font-size: 40px;

  }

  .homeText {
    font-size: 18px;
    font-weight: 600;
  }
}
.email-window {
    font-family: 'Barlow Condensed', sans-serif;
    color: black;
    font-size: large;
    background-color: white;
}

.email-container {
    padding: 2rem 0 3rem 0;
    margin: 3em auto;
}

.email-bg-pic {
    background: transparent;
}


.helllo {
    margin: auto;
    width: 50% ;
    font-family: 'Barlow Condensed', sans-serif;
    font-family: 'Roboto', sans-serif;
    color: white;
    -webkit-text-decoration: solid;
            text-decoration: solid;
    /* border-color: black; */
    font-size: 30px;
} 

.emailText {
    margin: 35px auto 5px auto;
    text-align: center;
    width: 50%;
    /* font-family: 'Barlow', sans-serif; */
    color: white;
    font-family: 'Roboto', sans-serif;
    text-shadow: black;
    font-size: 20px;
} 

.btn-email-send {
    background-color: grey !important;
    color: white!important;
    text-align: center;
    font-family: 'Barlow', sans-serif;
    font-weight: 500;
    font-size: large;
    letter-spacing: 1px;
    border: none !important;
    margin: 5px;
}

.btn-email-send:hover {
    background-color: black;
    color: white;
    text-decoration: none;
}




.aTag {
    width: 50px;
    padding: 5px 5px 25px 5px;
    z-index: 0;
}

.githubLogo {
    fill: white !important;
}


.aTag:hover {
    transition: ease .2s;
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    
}

.footer {
    text-align: center;
    width: 100%;
    bottom:0;
}

#scrollBTN{
    padding-top: 10px;
    padding-left: 18px;
    padding-bottom: 5px;
    padding-right: 18px; 
    font-size: 25px;
    border-radius: 0;
}

.fa {
    background-color: black;
    float: right;
    position: fixed;
    bottom: 50px;
    right: 18px;
    z-index: 99;
    color: white;
    padding-top: 5px;
    padding-left: 15px;
    padding-bottom: 5px;
    padding-right: 15px; 
    font-size: 45px;
    border-radius: 0;
    border: 4px black solid;
}

.fa:hover {
    background-color: white;
    color: black;
}

@media (max-width: 600px){
    .fa{
        bottom: 40px;
        right: 15px;
        padding-top: 5px;
        padding-left: 10px;
        padding-bottom: 5px;
        padding-right: 10px; 
        font-size: 30px;
    }
    .aTag{
        width: 40px;
    padding: 5px 5px 25px 5px;
    }
}





.navbar {
    background-color: #f6f6f6;
    font-weight: bold;
    font-family: 'Barlow Condensed', sans-serif;

}

.navLink {
    margin: 10px;
    background-color: #f6f6f6;
    color: black;
    text-align: center;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: 500;
    font-size: 19px;
    letter-spacing: 1px;
    padding: 0.2px 15px;
}

.navbar-brandJack {
    /* height: 50px; */
    width: 200px;
    /* margin: 24px; */
    background-color: black !important;
    color: #f6f6f6 !important;
    text-align: center;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: 500;
    font-size: xx-large;
    letter-spacing: 1px;
    padding-top: .5px !important;
    padding-bottom: .5px !important;
    justify-content: center;
    align-items: center;
    vertical-align: baseline !important;
    text-decoration: none !important;
}



.active,
.navLink:hover {
    background-color: black;
    color: white;
    text-decoration: none;
    padding-top: 5px;
    padding-bottom: 5px;
}

.navbar-nav {
    margin-top: 10px;
}

.navbar-toggler {
    margin-top: 0px;
    border-radius: 0;
    height: 30px;
    width: 30px;
    padding: 2px;
    outline: none !important;
    box-shadow: none;
}

.navbar-toggler:active .plus {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    outline: none !important;
    box-shadow: none;
}

@media (max-width: 540px) {
    .navbar-brand {
        margin-top: 5px;
        margin-bottom: 5px;
        margin-left: 20px;
    }
}
.webDevPage {
    background-color: #f6f6f6;
}
.devCardHeader {
    text-align: left;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 28px;
    letter-spacing: 1px;
    font-weight: 700;
}

.devText {
    text-align: left;
    font-family: 'Barlow', sans-serif;
    font-size: 24px;
    font-weight: 500;
    padding: 0;
}

.devCardStuff {
    display: inline-block;
    width: 50%;
    padding: 100px 0;
}

.devCardImage {
    width: 30%;
    border: 1px rgb(0, 0, 0, .75) solid !important;
    float: left;
    margin-right: 100px;
    border-radius: 0;
}

.devCardImage:hover {

}

.devCards {
    margin: 20px;
}

.project:hover {
    background-color: white;
    color: black;
    text-decoration: none;
}

.project {
    float: left !important;
    background-color: black;
    color: white;
    text-align: center;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 1px;
    border: 4px black solid;
    margin: 30px 10px 5px 5px;
    padding: 8px;
    border-radius: none;
}

@media (min-width: 320px) {
    .devCardHeader {
        display: inline-block;
        text-align: left;
        font-family: 'Barlow Condensed', sans-serif;
        font-size: 20px;
        letter-spacing: 1px;
        font-weight: 700;
    }
    
    .devText {
        text-align: left;
        font-family: 'Barlow', sans-serif;
        font-size: 15px;
        font-weight: 500;
    }

    .devCardStuff {
        display: inline-block;
        width: 80%;
        padding: 10px 0;
    }
    
    .devCardImage {
        display: inline-block;
        width: 80%;

    }
    .devCards{
        margin-left: 0;
    }
    .project{
        font-size: 14px;
        margin: 15px 10px 0px 0px;
    }
}

@media (min-width: 480px) {
    .devCardStuff {
        display: inline-block;
        width: 80%;
        padding: 10px 0;
    }
    
    .devCardImage {
        display: inline-block;
        width: 80%;
    }
    
}

@media (min-width: 600px) {
    .devCardHeader {
        text-align: left;
        font-family: 'Barlow Condensed', sans-serif;
        font-size: 18px;
        letter-spacing: 1px;
        font-weight: 700;
    }
    
    .devText {
        text-align: left;
        font-family: 'Barlow', sans-serif;
        font-size: 13px;
        font-weight: 500;
    }

    .devCardStuff {
        display: inline-block;
        width: 40%;
    }
    
    .devCardImage {
        float: left;
        width: 40%;
    }
    .devCards{
        margin-left: 0;
    }
}

@media (min-width: 768px) {
    .devCardStuff {
        display: inline-block;
        width: 40%;
        padding: 25px 0;
    }

    .project{
        float: left !important;
        font-size: 15px;
        margin: 15px 10px 0px 0px;
    }
}

@media (min-width: 1024px) {
    .devCardStuff {
        display: inline-block;
        width: 49.3%;
        padding: 25px 0
    }
    .devCardHeader {
        text-align: left;
        font-family: 'Barlow Condensed', sans-serif;
        font-size: 20px;
        letter-spacing: 1px;
        font-weight: 700;
    }
    
    .devText {
        text-align: left;
        font-family: 'Barlow', sans-serif;
        font-size: 18px;
        font-weight: 500;
    }
}

@media (min-width: 1200px) {
    .devCardHeader {
        text-align: left;
        font-family: 'Barlow Condensed', sans-serif;
        font-size: 28px;
        letter-spacing: 1px;
        font-weight: 700;
    }
    
    .devText {
        text-align: left;
        font-family: 'Barlow', sans-serif;
        font-size: 18px;
        font-weight: 500;
        padding: 0;
    }
    
    .devCardDeck {
        display: flex;
    }
    
    .devCardStuff {
        display: inline-block;
        width: 40%;
        padding: 5% 0;
    }
    
    .devCardImage {
        display: inline-block;
        width: 30%;
        margin-right: 100px;
    }
    
    .devCards {
        margin: 20px;
    }
}



.resume, .skills {
    margin-left: 10px;
    margin-top: 45px;
}

.resumeTitle, .companyName, .description, .jobTitle, .duration {
    color: white;
    margin-left: 15px;
    
}

.resumePage {
    background-color:black;
}

.resumeTitle {
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 28px;
    letter-spacing: 1px;
    font-weight: 700;
    color:black;
    background-color: white;
    padding: 5px 11px 5px 11px;
}

.companyName {
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 24px;
    letter-spacing: 1px;
    font-weight: 500;
    color: white;
    text-decoration: underline;
}

.description {
    font-family: 'Barlow', sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: white;
    line-height: 25px;
    text-align: left;
    margin: 3px 15px 10px;

}

.duration {
    text-align: center;
    font-family: 'Barlow', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: white;
    letter-spacing: .5px;
    text-align: left;
    margin-bottom: 0px;
    margin-left: 15px;
}

.skillList {
    text-align: center;
    font-family: 'Barlow', sans-serif;
    font-size: 17px;
    font-weight: 500;
    color: white;
    line-height: 30px;
    text-align: left;
}

.logo {
height: 25px;
width: 25px;
margin: 7px 15px 7px 0px;
}

.fa-ul {
    padding-left: 0;
    margin-left: 15px;
}

.fab {
    color: white;
    margin-right: 6px;
}




@media (min-width: 320px) {
    .resumeTitle{
        font-size: 15px;
    }

    .companyName{
        font-size: 15px;
    }
    .description{
        font-size: 14px;
    }
    .jobTitle{
        font-size: 14px;
    }
}

@media (min-width: 1024px) {
    .resumeTitle{
        font-size: 35px;
    }
    .companyName{
        font-size: 30px;
    }
    .description{
        font-size: 18px;
    }
}


.musicPage {
    background-color: #f6f6f6;
}
.cardHeader {
    text-align: left;
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 28px;
    letter-spacing: 1px;
    font-weight: 700;
}

.cardParagraph {
    text-align: left;
    font-family: 'Barlow', sans-serif;
    font-size: 24px;
    font-weight: 500;
    padding: 0;
}

.cardStuff {
    display: inline-block;
    width: 50%;
    padding: 200px 0;
}

.cardImage {
    background-color: black;
    width: 30%;
    border: 1px rgb(0, 0, 0, .75) solid !important;
    float: left;
    margin-right: 100px;
    border-radius: 0;
    opacity: 1;
    transition: .5s ease;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}

.homeTitle {
    font-family: 'Barlow', sans-serif;
    background-color: transparent;
    color: black;
    font-size: 18px;
    padding: 16px 32px;
    font-weight: 500;
}

.middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    margin: 17% 15%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.imgDiv {
position: relative;
}

.imgDiv:hover .cardImage {
    opacity: 0.3;
}

.imgDiv:hover .middle {
    opacity: 1;
}

.text {
    font-family: 'Barlow', sans-serif;
    background-color: transparent;
    color: black;
    font-size: 16px;
    padding: 16px 32px;
    font-weight: 400;
  }

.cards {
    margin: 20px;
}

@media (min-width: 320px) {
    .middle{
        margin: 45% 40%;
        width: 90%;
    }
    .cards {
        margin-left: 0px;
    }
    .card-deck {
        justify-content: inherit;
    }
    .cardImage {
        float: left;
        width: 80%;
        margin-left: auto;
    } 
    .cardStuff {
        display: inline-block;
        width: 95%;
        padding: 10px 0;
    }
    .cardHeader {
        display: inline-block;
        text-align: left;
        font-family: 'Barlow Condensed', sans-serif;
        font-size: 20px;
        letter-spacing: 1px;
        font-weight: 700;
    }
    
    .cardParagraph {
        text-align: left;
        font-family: 'Barlow', sans-serif;
        font-size: 15px;
        font-weight: 500;
    }
}

  /* Small devices (portrait tablets and large phones, 600px and up) */
    @media (min-width: 480px) {
        .middle{
            margin: 45% 38%;
            width: 60%;
        }
        .cards {
            margin-left: 0;
        }
        .cardImage {
            float: left;
            width: 80%;
        } 
        .cardStuff {
            display: inline-block;
            width: 80%;
            padding: 10px 0;
        }
        .cardHeader {
            display: inline-block;
            text-align: left;
            font-family: 'Barlow Condensed', sans-serif;
            font-size: 20px;
            letter-spacing: 1px;
            font-weight: 700;
        }
        
        .cardParagraph {
            text-align: left;
            font-family: 'Barlow', sans-serif;
            font-size: 15px;
            font-weight: 500;
        }

}

  @media (min-width: 600px) {
    .middle{
        margin: 22% 20%;
        width: 40%;
    }
    .cardHeader {
        text-align: left;
        font-family: 'Barlow Condensed', sans-serif;
        font-size: 18px;
        letter-spacing: 1px;
        font-weight: 700;
    }
    
    .cardParagraph {
        text-align: left;
        font-family: 'Barlow', sans-serif;
        font-size: 13px;
        font-weight: 500;
        padding: 0;
    }
    
    .cardStuff {
        display: inline-block;
        width: 40%;
        padding: 10px 0;
        
    }
    .cardImage {
        width: 40%;
        float: left;
    }
    
} 
  /* Medium devices (landscape tablets, 768px and up) */
    @media (min-width: 768px) {
        .middle{
            margin: 22% 20%;
            width: 40%;
        }
        .card-deck{
            margin-right:  10px;
        }
        .cardHeader {
            text-align: left;
            font-family: 'Barlow Condensed', sans-serif;
            font-size: 20px;
            letter-spacing: 1px;
            font-weight: 700;
        }
        
        .cardParagraph {
            text-align: left;
            font-family: 'Barlow', sans-serif;
            font-size: 18px;
            font-weight: 500;
        }
        
        .cardStuff {
            display: inline-block;
            width: 45%;
            padding: 20px 0;
            
        }
        
        .cardImage {
            width: 40%;
            float: left;
        }
        
        .cards {
            margin: 10px 10px 10px 20px;
        }
    
  }  
  /*  Large devices (laptops/desktops, 992px and up) */
    @media (min-width: 1024px) {
        .middle{
            margin: 22% 20%;
            width: 40%;
        }
        .cardStuff {
            display: inline-block;
            width: 40%;
            padding: 70px 0;
            
        }

  } 
  @media (min-width: 1200px) {  
        .middle{
    margin: 17% 15%;
    width: 40%;
}
    .cardHeader {
        font-size: 28px;
    }
    .cardParagraph {
        font-size: 18px;
    }
    .cards {
        margin: 20px;
    }
    .cardImage{
        width: 30%;
    }
    .cardStuff{
        width: 30%;
    }

}




#root {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; 
    }

    
html {
    scroll-behavior: smooth;
}
