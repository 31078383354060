.navbar {
    background-color: #f6f6f6;
    font-weight: bold;
    font-family: 'Barlow Condensed', sans-serif;

}

.navLink {
    margin: 10px;
    background-color: #f6f6f6;
    color: black;
    text-align: center;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: 500;
    font-size: 19px;
    letter-spacing: 1px;
    padding: 0.2px 15px;
}

.navbar-brandJack {
    /* height: 50px; */
    width: 200px;
    /* margin: 24px; */
    background-color: black !important;
    color: #f6f6f6 !important;
    text-align: center;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: 500;
    font-size: xx-large;
    letter-spacing: 1px;
    padding-top: .5px !important;
    padding-bottom: .5px !important;
    justify-content: center;
    align-items: center;
    vertical-align: baseline !important;
    text-decoration: none !important;
}



.active,
.navLink:hover {
    background-color: black;
    color: white;
    text-decoration: none;
    padding-top: 5px;
    padding-bottom: 5px;
}

.navbar-nav {
    margin-top: 10px;
}

.navbar-toggler {
    margin-top: 0px;
    border-radius: 0;
    height: 30px;
    width: 30px;
    padding: 2px;
    outline: none !important;
    box-shadow: none;
}

.navbar-toggler:active .plus {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    outline: none !important;
    box-shadow: none;
}

@media (max-width: 540px) {
    .navbar-brand {
        margin-top: 5px;
        margin-bottom: 5px;
        margin-left: 20px;
    }
}